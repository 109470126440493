import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import './list.scss'

const ListPage = ({ data }) => {
  const recipes = data.allStrapiRecipe.edges.map(
    ({ node: { title, slug } }) => (
      <li>
        <Link to={`/recept/${slug}`}>{title}</Link>
      </li>
    )
  )

  return (
    <Layout title="Alla recept">
      <div className="list-page subpage">
        <h1>Alla recept</h1>
        <ul className="list">{recipes}</ul>
      </div>
    </Layout>
  )
}

export default ListPage

export const pageQuery = graphql`
  query RecipeListQuery {
    allStrapiRecipe(sort: { order: ASC, fields: title }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
